import { Html5Qrcode } from "html5-qrcode";
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["scanner"];

  initialize() {
    super.initialize();

    this.config = { fps: 10, qrbox: { width: 350, height: 350 } };
  }

  connect() {
    super.connect();

    this.html5QrCode = new Html5Qrcode(this.scannerTarget.id);
  }

  async start() {
    // On an iPad camera permission prompt by the browser is behind
    // the fullscreen button in an alert. So we need to request permission
    // before we can start the scanner because the scanner itself does
    // not provide a way to run a callback right after the camera permission
    // is granted.
    await this.#requestCameraPermission();

    // By going fullscreen mode first we make sure that the camera video canvas
    // is going to be full height and width of the screen.
    // this.toggleFullscreen();
    this.element.classList.replace("d-none", "fullscreen");

    this.html5QrCode.start(
      { facingMode: "environment" },
      this.config,
      this.onSuccess.bind(this),
    );
  }

  cancel() {
    this.html5QrCode.stop();
    this.element.classList.replace("fullscreen", "d-none");
  }

  onSuccess(decodedText, decodedResult) {
    console.log(decodedText);
    this.html5QrCode.stop();

    this.dispatch("success", {
      detail: { decodedText: decodedText, decodedResult: decodedResult },
    });
  }

  async #requestCameraPermission() {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      console.log("Camera permission granted");
      // Immediately stop the stream since we don't need it
      stream.getTracks().forEach((track) => track.stop());
    } catch (error) {
      console.error("Error requesting camera permission:", error);
    }
  }
}
