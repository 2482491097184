import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  toggleFullscreen() {
    if (!document.fullscreenElement) {
      if (this.element.requestFullscreen) {
        this.element.requestFullscreen();
      } else if (this.element.mozRequestFullScreen) {
        // Firefox, see https://caniuse.com/mdn-api_element_requestfullscreen
        this.element.mozRequestFullScreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      }
    }
  }
}
