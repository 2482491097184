import { Controller } from "@hotwired/stimulus";
import { pieApiFetch } from "shared/fetch_decorators";

export default class extends Controller {
  static targets = ["title", "documentTypeSelector"];
  static values = { orderId: String, newProof: Boolean, documentType: String };

  changeDocumentType(event) {
    if (!this.newProofValue) {
      return;
    }

    const selectedOption = event.target.selectedOptions[0];

    this.#setTitle(selectedOption.text);

    if (selectedOption.value == "7") {
      this.dispatch("vjwChoosen");
    } else if (selectedOption.value == "1") {
      this.dispatch("passportChoosen");
    }
  }

  async submitVjwQrCode(event) {
    const decodedText = event.detail.decodedText;
    const options = {
      method: "POST",
      body: {
        visit_japan_web_qr_code_str: decodedText,
      },
    };

    await pieApiFetch(
      `/api/orders/${this.orderIdValue}/visit_japan_web_qr_code`,
      options,
      this.scope,
    );

    window.location.reload();
  }

  // PRIVATE

  #setTitle(text) {
    this.titleTarget.innerText = text;
  }
}
