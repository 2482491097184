import { Controller } from "@hotwired/stimulus";

// Used to submit the form when the user clicks the next step button.
export default class extends Controller {
  static targets = ["form", "footer", "nextStep"];
  static values = { intercomAppId: String };

  initialize() {
    // https://developers.intercom.com/installing-intercom/web/customization#messenger-alignment
    window.intercomSettings = {
      app_id: this.intercomAppIdValue,
      alignment: "left",
      horizontal_padding: 20,
      vertical_padding: 90,
    };
  }

  submitForm(event) {
    if (this.hasFormTarget) {
      event.preventDefault();
      this.formTarget.requestSubmit();
    }
  }

  hideFooter() {
    this.footerTarget.classList.add("d-none");
  }

  showFooter() {
    this.footerTarget.classList.remove("d-none");
  }

  hideNextStep() {
    this.nextStepTarget.classList.add("d-none");
  }

  showNextStep() {
    this.nextStepTarget.classList.remove("d-none");
  }
}
