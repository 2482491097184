import { Controller } from "@hotwired/stimulus";
import { pieApiFetch } from "shared/fetch_decorators";
import { Html5QrcodeScanner } from "html5-qrcode";

/*
 * Visit Japan Web QR Code Controller to initialize the QR Code scanner
 * on the web.
 */
export default class extends Controller {
  static targets = ["scanner"];

  connect() {
    // These config settings were chosen arbitrarily.
    this.html5QrcodeScanner = new Html5QrcodeScanner(
      "qr-code-scanner",
      /* config= */ {
        fps: 10,
        qrbox: (_w, _h) => {
          return { width: 550, height: 550 };
        },
      },
      /* verbose= */ false,
    );
  }

  renderScanner() {
    this.html5QrcodeScanner.render(
      this.onScanSuccess.bind(this),
      this.onScanFailure,
    );
  }

  async onScanSuccess(decodedText, decodedResult) {
    // handle the scanned code as you like, for example:
    console.log(`Code matched = ${decodedText}`, decodedResult);
    this.html5QrcodeScanner.pause(true);
    const options = {
      method: "POST",
      body: {
        visit_japan_web_qr_code_str: decodedText,
      },
    };

    const orderId = document.getElementById("qr-code-scanner").dataset.orderId;

    await pieApiFetch(
      `/api/orders/${orderId}/visit_japan_web_qr_code`,
      options,
    );

    window.location.reload();
  }
  onScanFailure(_error) {
    // handle scan failure, usually better to ignore and keep scanning.
    // for example:
    // console.warn(`Code scan error = ${error}`);
  }
}
