import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static outlets = ["jp-self-service--line-item"];
  static targets = [
    "addNewItem",
    "consumableItemsTax",
    "consumableItemsTotal",
    "eightPercentTax",
    "eightPercentTotal",
    "generalItemsTax",
    "generalItemsTotal",
    "orderTax",
    "orderTotal",
    "required",
    "tenPercentTax",
    "tenPercentTotal",
    "totalPrice",
    "totalTax",
    "itemsCounter",
  ];

  connect() {
    if (!this.hasJpSelfServiceLineItemOutlet && this.hasAddNewItemTarget) {
      this.addNewItemTarget.click();
    }

    this.refresh();
  }

  jpSelfServiceLineItemOutletConnected(outlet, _) {
    this.refresh();

    outlet.triggersRefreshTargets.forEach((target) => {
      target.addEventListener("change", this.refresh.bind(this));
    });
  }

  jpSelfServiceLineItemOutletDisconnected(outlet, _) {
    this.refresh();

    outlet.triggersRefreshTargets.forEach((target) => {
      target.removeEventListener("change", this.refresh.bind(this));
    });
  }

  refresh() {
    this.orderTotalTarget.innerHTML = this.calculateOrderTotal();
    this.orderTaxTarget.innerHTML = this.calculateOrderTax();
    this.tenPercentTotalTarget.innerHTML = this.calculateTenPercentTotal();
    this.tenPercentTaxTarget.innerHTML = this.calculateTenPercentTax();
    this.eightPercentTotalTarget.innerHTML = this.calculateEightPercentTotal();
    this.eightPercentTaxTarget.innerHTML = this.calculateEightPercentTax();
    this.generalItemsTotalTarget.innerHTML = this.calculateGeneralItemsTotal();
    this.generalItemsTaxTarget.innerHTML = this.calculateGeneralItemsTax();
    this.consumableItemsTotalTarget.innerHTML =
      this.calculateConsumableItemsTotal();
    this.consumableItemsTaxTarget.innerHTML =
      this.calculateConsumableItemsTax();

    this.itemsCounterTarget.innerHTML = this.items().filter(
      (outlet) => !outlet.markedForDestruction(),
    ).length;
  }

  calculateOrderTotal() {
    let sum = 0;

    this.items().forEach((outlet) => {
      if (!outlet.markedForDestruction()) {
        sum += parseFloat(outlet.totalPrice);
      }
    });

    return this.formatAmount(sum);
  }

  items() {
    return this.jpSelfServiceLineItemOutlets;
  }

  calculateOrderTax() {
    let sum = 0;

    this.items().forEach((outlet) => {
      if (!outlet.markedForDestruction()) {
        sum += parseFloat(outlet.tax);
      }
    });

    return this.formatAmount(sum);
  }

  calculateTenPercentTotal() {
    let sum = 0;

    this.items().forEach((outlet) => {
      if (!outlet.markedForDestruction() && outlet.isStandardTaxRate) {
        sum += parseFloat(outlet.totalPrice);
      }
    });

    return this.formatAmount(sum);
  }

  calculateTenPercentTax() {
    let sum = 0;

    this.items().forEach((outlet) => {
      if (!outlet.markedForDestruction() && outlet.isStandardTaxRate) {
        sum += parseFloat(outlet.tax);
      }
    });

    return this.formatAmount(sum);
  }

  calculateEightPercentTotal() {
    let sum = 0;

    this.items().forEach((outlet) => {
      if (!outlet.markedForDestruction() && outlet.isReducedTaxRate) {
        sum += parseFloat(outlet.totalPrice);
      }
    });

    return this.formatAmount(sum);
  }

  calculateEightPercentTax() {
    let sum = 0;

    this.items().forEach((outlet) => {
      if (!outlet.markedForDestruction() && outlet.isReducedTaxRate) {
        sum += parseFloat(outlet.tax);
      }
    });

    return this.formatAmount(sum);
  }

  calculateGeneralItemsTotal() {
    let sum = 0;

    this.items().forEach((outlet) => {
      if (!outlet.isConsumable) {
        sum += parseFloat(outlet.totalPrice);
      }
    });

    return this.formatAmount(sum);
  }

  calculateGeneralItemsTax() {
    let sum = 0;

    this.items().forEach((outlet) => {
      if (!outlet.isConsumable) {
        sum += parseFloat(outlet.tax);
      }
    });

    return this.formatAmount(sum);
  }

  calculateConsumableItemsTotal() {
    let sum = 0;

    this.items().forEach((outlet) => {
      if (!outlet.markedForDestruction() && outlet.isConsumable) {
        sum += parseFloat(outlet.totalPrice);
      }
    });

    return this.formatAmount(sum);
  }

  calculateConsumableItemsTax() {
    let sum = 0;

    this.items().forEach((outlet) => {
      if (!outlet.markedForDestruction() && outlet.isConsumable) {
        sum += parseFloat(outlet.tax);
      }
    });

    return this.formatAmount(sum);
  }

  formatAmount(amount) {
    return new Intl.NumberFormat("ja-JP", {
      style: "currency",
      currency: "JPY",
      maximumFractionDigits: 0,
    }).format(amount);
  }

  toggleReducedTaxRate() {
    this.#toggleApplyAllAction(
      "toggleReducedTaxRateIcon",
      (item) => !item.isReducedTaxRate,
    );
  }

  toggleConsumable() {
    this.#toggleApplyAllAction(
      "toggleConsumableIcon",
      (item) => !item.isConsumable,
    );
  }

  toggleTaxIncludedInTotal() {
    this.#toggleApplyAllAction(
      "toggleTaxIncludedInTotalIcon",
      (item) => !item.taxIncludedInTotal,
    );
  }

  toggleAlcohol() {
    this.#toggleApplyAllAction("toggleAlcoholIcon", (item) => !item.isAlcohol);
  }

  #toggleApplyAllAction(targetName, filter) {
    if (this.items().some(filter)) {
      this.items()
        .filter(filter)
        .forEach((item) => {
          item.targets.find(targetName).click();
        });
    } else {
      this.items().forEach((item) => {
        item.targets.find(targetName).click();
      });
    }
  }
}
